export const appKeepalive = true

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"name":"baidu-site-verification","content":"3zVgcSwTyl"},{"http-equiv":"x-ua-compatible","content":"IE=edge"},{"http-equiv":"cache-control","content":"no-cache"},{"http-equiv":"Content-Security-Policy","content":"default-src 'self' *.qcwp.com *.qctsw.com *.bcebos.com *.dongchedi.com data: 'unsafe-inline' ; img-src * data: blob:; media-src * data: blob:; script-src 'self' 'unsafe-inline' 'unsafe-eval' *; worker-src 'self' data: blob:; child-src 'self' *.qq.com; connect-src * rtmp:; font-src * data:;"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"dns-prefetch","href":"https://qcts.cdn.bcebos.com/"},{"rel":"dns-prefetch","href":"https://qcts.gz.bcebos.com/"},{"rel":"dns-prefetch","href":"https://hm.baidu.com/"}],"style":[],"script":[{"children":"\n                var _hmt = _hmt || [];\n                (function() {var hm = document.createElement(\"script\"); hm.src = \"https://hm.baidu.com/hm.js?89d3c5fc8f91cbcd5bf2677a44b616ea\"; var s = document.getElementsByTagName(`script`)[0]; s.parentNode.insertBefore(hm, s);})();\n              "}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'