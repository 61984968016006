// import { isWechat } from '@qcwp/utils'
import type {
  CanLoginMedia,
  ForgetParams, GetLoginMediaParams, LoginParams,
  MediaThirdpartyResponse,
  MediaUserSubjectResponse,
  RegisterParams,
  TokenResponse,
  UserBaseInfoParams,
  UserInfoResponse,
  UserPhoneParams,
  UserSubjectAuthParams,
  VerifyCodeParams,
} from './type'
import type { FetchResponseType } from '~~/src/server/request/type'
import { useApiFetch } from '~~/src/server/request'

/**
 * 验证码发送
 * @params phone
 * @returns Promise<FetchResponseType<boolean>>
 */
export async function getVerification(params: VerifyCodeParams) {
  return useApiFetch<FetchResponseType<boolean>>('/base/portal/verifycode', {
    method: 'POST',
    params,
  })
}

/**
 * 验证码发送
 * @params phone
 * @returns Promise<FetchResponseType<boolean>>
 */
export async function imgCodeApi() {
  return useApiFetch<FetchResponseType<{
    img: string
    uuid: string
  }>>('/code', {
    method: 'GET',

  })
}
/**
 * 判断账号命是否被注册
 */
export async function validatorAccount(account: string) {
  return useApiFetch<FetchResponseType<boolean>>('/api/webportal/auth/existAccount', {
    method: 'GET',
    params: { account },
  })
}

/**
 * 获取微信授权二维码地址
 */
export function getWxAuthUrl(redirectUrl: string) {
  return useApiFetch<FetchResponseType<string>>('/third/wx/open/qrcode', {
    method: 'GET',
    params: { redirectUrl },
  })
}

/**
 * 获取可以进行授权登录的媒体列表
 * @params GetLoginMediaParams
 * @returns Promise<FetchResponseType<CanLoginMedia[]>>
 */
export function getLoginMedia(params: GetLoginMediaParams) {
  return useApiFetch<FetchResponseType<CanLoginMedia[]>>('/news/portal/mediaUser/list', {
    method: 'GET',
    params,
  })
}

/**
 * 忘记密码
 * @params ForgetParams
 * @returns Promise<FetchResponseType<boolean>>
 */
export async function forget(params: ForgetParams) {
  return useApiFetch<FetchResponseType<boolean>>('/auth/portal/forgetThePassword', {
    method: 'POST',
    body: { ...params },
  })
}

/**
 * 账号注册
 * @params ForgetParams
 * @returns Promise<FetchResponseType<string>> mediaId
 */
export async function register(params: RegisterParams) {
  return useApiFetch<FetchResponseType<string>>('/auth/portal/register', {
    method: 'POST',
    body: { ...params },
  })
}

/**
 * 刷新登录状态
 * @returns Promise<FetchResponseType<userInfoResponse>>
 */
export function loginStatusRefresh() {
  return useApiFetch<FetchResponseType<TokenResponse>>('/auth/portal/refresh', {
    method: 'POST',
  })
}

/**
 * 登录
 * @param params LoginParams
 * @returns Promise<FetchResponseType<TokenResponse>>
 */
export function login(params: Partial<LoginParams>) {
  return useApiFetch<FetchResponseType<TokenResponse>>('/auth/portal/login', {
    method: 'POST',
    body: { ...params },
  })
}

/**
 * 退出登录
 * @returns Promise<FetchResponseType<boolean>>
 */
export function logout() {
  return useApiFetch<FetchResponseType<boolean>>('/auth/portal/logout', {
    method: 'DELETE',
    setting: {
      noPrompts: true,
    },
  })
}

/**
 * 获取用户信息
 * @returns Promise<FetchResponseType<UserInfoResponse>>
 */
export function getUserInfo() {
  return useApiFetch<FetchResponseType<Omit<UserInfoResponse, 'type'>>>('/news/user/mediaUser/info', {
    method: 'GET',
  })
}

/**
 * 获取登录者主体信息
 * @returns Promise<FetchResponseType<UserInfoResponse>>
 */
export function getUserSubjectInfo() {
  return useApiFetch<FetchResponseType<MediaUserSubjectResponse>>('/news/user/mediaUser/subject', {
    method: 'GET',
  })
}

/**
 * 获取登录者第三方信息
 * @returns Promise<FetchResponseType<UserInfoResponse>>
 */
export function getUserThirds() {
  return useApiFetch<FetchResponseType<MediaThirdpartyResponse[]>>('/news/user/mediaUser/thirds', {
    method: 'GET',
  })
}

/**
 *  主体认证
 * @param params UserSubjectAuthParams
 * @returns FetchResponseType<boolean>
 */
export function userSubjectAuth(params: UserSubjectAuthParams) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/mediaUser/submitSubjectCertification', {
    method: 'POST',
    body: { ...params },
  })
}

/**
 * 修改基本信息
 * @param params Partial<UserBaseInfoParams>
 * @returns FetchResponseType<boolean>
 */
export function userBaseInfoUpdate(params: Partial<UserBaseInfoParams>) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/mediaUser/updateUserInfo', {
    method: 'PUT',
    body: { ...params },
  })
}

/**
 * 修改网评号手机号
 * @param params UserPhoneParams
 * @returns FetchResponseType<boolean>
 */
export function userPhoneUpdate(params: UserPhoneParams) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/mediaUser/updateUserPhone', {
    method: 'PUT',
    body: { ...params },
  })
}
